import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import { Layout } from "../components/common";
import { MetaData } from "../components/common/meta";
import getAuthorProperties from "../components/common/meta/getAuthorProperties";
import { AuthorInfo, ShareThis, Subscribe } from "../components";

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */
const Post = ({ data, location }) => {
    const post = data.ghostPost;
    const author = getAuthorProperties(data.ghostPost.primary_author);

    return (
        <>
            <MetaData data={data} location={location} type="article" />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout>
                <div className="container">
                    <article className="content">
                        <section className="post-full-content">
                            <h1 className="content-title">{post.title}</h1>
                            <p>{post.published_at_pretty}</p>
                            <p>{post.custom_excerpt}</p>
                        </section>
                        {post.feature_image ? (
                            <figure className="post-feature-image">
                                <img
                                    src={post.feature_image}
                                    alt={post.title}
                                />
                            </figure>
                        ) : null}
                        <section className="post-full-content">
                            {/* The main post content */}
                            <section className="content-body load-external-scripts">
                                <section
                                    dangerouslySetInnerHTML={{
                                        __html: post.html,
                                    }}
                                />
                                <ShareThis post={post} />
                                <Subscribe />
                                <AuthorInfo author={author} />
                            </section>
                        </section>
                    </article>
                </div>
            </Layout>
        </>
    );
};

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
            published_at_pretty: PropTypes.string,
            custom_excerpt: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default Post;

export const postQuery = graphql`
    query ($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`;
